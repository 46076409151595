import { scrollAnimation } from './function/scroll-animation.js';
const tile = require('./lib/tile.js');

// ===========================================
// スクロールストップイベント
// ===========================================
let newEvent = new $.Event("scrollstop"),
    timer;

function newEventTrigger(){

  if (timer) clearTimeout(timer);

  timer = setTimeout(function(){
    $(window).trigger(newEvent)
  }, 200);
}

$(window).on("scroll", newEventTrigger);

// ===========================================
// スムーススクロールの関数
// ===========================================
$(function () {
  $('a[href^="#"]').click(function () {
    const speed = 500,
        href = $(this).attr("href"),
        target = $(href == "#" || href == "" ? "html" : href),
        position = target.offset().top;

    $("html, body").animate(
      {
        scrollTop: position,
      },
      speed,
      "swing"
    );
    return false;
  });
});


// ===========================================
// スクロールアニメーションのオプション
// ===========================================
scrollAnimation({
  className: "a-fadeIn",
  triggerPoint: 150,
});

scrollAnimation({
  className: "c-keyVisual",
  triggerPoint: 150,
});

scrollAnimation({
  className: "c-localNav",
  triggerPoint: 150,
});


// ===========================================
// ヘッダーのスクロール時のインタラクション
// ===========================================
$(function () {
  const header = $('.t-pageHeader'),
        hamburger = $('.t-hamburgerBtn');;
  let scroll = $(window).scrollTop(),
      hidePoint = scroll;

  /*ヘッダーの固定 / 変動、表示 / 非表示 を判定する関数*/
  function headerInteraction( header, hamburger, scroll ) {
    if ( scroll > hidePoint ) {
      header.addClass("hide");
      hamburger.addClass("hide");
    } else if(
      scroll <= hidePoint ||
      scroll <= 0
    ) {
      header.removeClass("hide");
      hamburger.removeClass("hide");
    }

    hidePoint = scroll;
  }

  header.each(function(){
    headerInteraction( header, hamburger, scroll );

    $(window).on("scrollstop", function(){
      scroll = $(window).scrollTop();

      headerInteraction( header, hamburger, scroll );
    });

    $(window).on("resize", function () {
      scroll = $(window).scrollTop();

      headerInteraction( header, hamburger, scroll );
    });
  });
});


// ===========================================
// グローバルナビのアコーディオン
// ===========================================
$(function () {
  $('.t-headerAccordionParent--mobile').each(function(){
    const btn = $(this),
          accordion = btn.next('.t-pageHeaderAccordionWrapper');
    let time = 0;

    btn.on('click', function(){
      btn.toggleClass('open');
      accordion.slideToggle(300);
      btn.css({ pointerEvents: 'none' });

      setTimeout(function () {
        btn.css({ pointerEvents: 'auto' });
      }, 350);
    });
  });
});


// ===========================================
// フッターナビのアコーディオン
// ===========================================
$(function () {
  $('.t-footerSiteMap__btn--accordionParent').each(function(){
    const btn = $(this),
          accordion = btn.next('.t-footerSiteMapListWrapper');
    let time = 0,
        beforeWidth = $(window).width();

    btn.on('click', function(){
      accordion.slideToggle(300);
      btn.css({ pointerEvents: 'none' });

      setTimeout(function () {
        btn.css({ pointerEvents: 'auto' });
      }, 350);
    });

    $(window).on('resize', function(){
      if(
        beforeWidth !== $(window).width() &&
        $(window).width() > 1024
      ) {
        accordion.css({ display: 'block' });
      } else if(
        beforeWidth !== $(window).width() &&
        $(window).width() <= 1024
      ) {
        accordion.css({ display: 'none' });
      }
    });
  });
});


// ===========================================
// ハンバーガーメニュー展開時のディレイ
// ===========================================
$(function () {
  let time = 0;

  $("#t-hamburgerCheck").on("change", function (){
    const checkBox = $(this);

    if( checkBox.prop('checked') === true ) {
      time = 0;

      $('.t-pageHeaderNav__item').each(function(){
        time = time + .1;
        $(this).css({ transitionDelay: time + 's' });
      });

      $('.t-pageHeaderCorp').each(function(){
        time = time + .1;
        $(this).css({ transitionDelay: time + 's' });
      });

      $('.t-pageHeaderSubNav').each(function(){
        time = time + .1;
        $(this).css({ transitionDelay: time + 's' });
      });
    } else {
      time = 0;

      $('.t-pageHeaderNav__item, .t-pageHeaderCorp, .t-pageHeaderSubNav').each(function(){
        $(this).css({ transitionDelay: time + 's' });
      });
    }
  });

});

// ===========================================
// ハンバーガーメニュー展開時にスクロールを制限する
// ===========================================
$(function () {
  $("#t-hamburgerCheck").on("change", function () {
    setTimeout(function () {
      if ($("#t-hamburgerCheck").prop("checked") === true) {
        $("html").css({ overflow: "hidden" });
      } else {
        $("html").css({ overflow: "auto" });
      }
    }, 100);
  });

  let widthBefore = $(window).width();

  $(window).on("resize", function () {
    if (widthBefore !== $(window).width()) {
      $("html").css({ overflow: "auto" });
      $("#t-hamburgerCheck").prop("checked", false);
      widthBefore = $(window).width();
    }
  });
});


// ===========================================
// テーブルのレイアウト
// ===========================================
$(function () {
  $('.c-articleBody table').each(function(){
    const table = $(this);

    table.after(
      '<div class="c-tableArea"><div class="c-tableAreaInner"><div class="c-tableWrapper">' +
        '<table>' + table.html() + '</table>' +
      '</div></div></div>'
    );

    table.remove();
  });
});


// ===========================================
// グロナビ押下時のインタラクション
// ===========================================
$(function () {
  $('.t-pageHeaderNav__item a').on('click', function(){
    $('#t-hamburgerCheck').prop('checked', false);
    $('html').css({ overflow: 'auto' });
  });
});


// ===========================================
// よくある質問
// ===========================================
$(function () {
  $('.c-qaData').each(function(){
    const head = $(this).find('.c-qaDataHead'),
          body = $(this).find('.c-qaDataBody');

    head.on('click', function(){
      head.toggleClass('open')
      body.slideToggle(400);
    });
  });
});


// ===========================================
// 診療案内
// ===========================================
$(function () {
  $('.c-medicalInfo__item').each(function(){
    const head = $(this).find('.c-medicalInfoHead'),
          body = $(this).find('.c-medicalInfoBody');

    head.on('click', function(){
      head.toggleClass('open')
      body.slideToggle(400);
    });
  });
});


// ===========================================
// サービスリストエリア
// ===========================================
$(function () {
  let maxNum;

  function titleSet( area, num1, num2 ) {
    if( num1 > num2 ) {
      maxNum = num1;
    } else if( num1 < num2 ) {
      maxNum = num2;
    } else if( num1 === num2 ) {
      maxNum = num1;
    }

    area.find('.c-serviceList__item').css({ height: 'auto' });

    for ( let i = 1; i < maxNum; i++ ) {
      area.find('.c-serviceList__item:nth-child(' + i + ')').tile();
    }
  }

  $('.c-serviceListAreaInner').each(function(){
    const area = $(this),
          num1 = area.find('.c-serviceList:nth-child(1)').find('.c-serviceList__item').length,
          num2 = area.find('.c-serviceList:nth-child(2)').find('.c-serviceList__item').length;

    titleSet( area, num1, num2 );

    $(window).on('resize', function(){
      titleSet( area, num1, num2 );
    });
  });
});


// ===========================================
// セレクトボックス
// ===========================================
$(function () {
  $('.c-selectBox').each(function(){
    const box = $(this),
          print = box.find('.c-selectBoxPrint'),
          bg = box.find('.c-selectBoxBg'),
          list = box.find('.c-selectBoxList'),
          radio = box.find('.c-selectBoxList__itemInner input[type="radio"]');

    print.on('click', function(){
      box.not('.c-selectBoxBg').addClass('open');
    });

    bg.on('click', function(){
      box.removeClass('open');
    });

    list.on('click', function(){
      box.removeClass('open');
    });

    radio.on('click', function(){
      let value = $(this).attr('data-name');

      if( value === 'すべて' ) {
        value = '選択してください';
      }

      print.html( value );
    });
  });
});