import { scrollAnimation } from './function/scroll-animation.js';
const slick = require('/assets/js/lib/slick/slick.js');
const tile = require('./lib/tile.js');
import '/assets/js/common.js';
import '/assets/js/lib/slick/slick.css';
import '/assets/css/common.scss';
import '/assets/css/top.scss';

// ===========================================
// スクロールアニメーションのオプション
// ===========================================
scrollAnimation({
  className: "mainVisual",
  triggerPoint: 150,
});

scrollAnimation({
  className: "partner",
  triggerPoint: 150,
});

scrollAnimation({
  className: "information",
  triggerPoint: 150,
});

scrollAnimation({
  className: "informationList",
  triggerPoint: 150,
});


// ===========================================
// オープニングアニメーション
// ===========================================
$(function () {
  $('.opening').each(function(){
    $(this).addClass('on');
  });
});


// ===========================================
// スライドショー
// ===========================================
$(function () {
  let slideNum = 1;

  function slideCount(mv, slideNum, maxSlideNum) {
    mv.find('input[name="mvSlide"][value="' + slideNum + '"]').prop('checked', true);
    slideNum++;
    if( slideNum > maxSlideNum ) {
      slideNum = 1;
    }

    setTimeout(function () {
      slideCount(mv, slideNum, maxSlideNum)
    }, 8000);
  }

  $('.mainVisual').each(function(){
    const mv = $(this),
          style = mv.prev(),
          maxSlideNum = mv.find('input[name="mvSlide"]').length;

    mv.find('input[name="mvSlide"]').each(function(n){
      style.append(
        'input[name="mvSlide"][value="' + (n + 1) + '"]:checked ~ .mvSlide .mvSlide__item:nth-child(' + (n + 1) + ') {' +
          'z-index: 100;' +
          'opacity: 1;' +
        '}' +
        'input[name="mvSlide"][value="' + (n + 1) + '"]:checked ~ .mvSlide .mvSlide__item:nth-child(' + (n + 1) + ') img {' +
          'transform: scale(1.2) translateX(-6%);' +
          'transition-delay: 0s;' +
        '}' +
        'input[name="mvSlide"][value="' + (n + 1) + '"]:checked ~ .mvSlideNav .mvSlideNav__item:nth-child(' + (n + 1) + ')::after {' +
          'transform: scaleX(1);' +
          'transition-duration: 8s;' +
          'transition-timing-function: linear;' +
          'transform-origin: left;' +
        '}'
      );
    });

    slideCount(mv, slideNum, maxSlideNum);

  });
});


// ===========================================
// パララックス
// ===========================================
$(function () {
  // パララックスの処理の関数
  function parallaxExecution( parallaxItem, rate ) {
    const position = parallaxItem.offset().top,
          scroll = $(window).scrollTop(),
          windowHeight = $(window).height();

    if ( $(window).width() > 1024 ) {
      if( scroll + $(window).height() > position - 300 ) {
        let parallaxValue =  ( ( parallaxItem.height() * 1.5 ) + ( -1 * (scroll + $(window).height() - position) ) )  / rate;

        parallaxItem.css({
          transform: "translateY(" + parallaxValue + "px) scale(1.2)"
        });
      }
    } else {
      parallaxItem.attr('style', '');
    }
  }

  // パララックスの処理を実行する関数
  function parallax( parallaxObject, rate ) {
    parallaxExecution( parallaxObject, rate );

    $(window).on('scroll', function(){
      parallaxExecution( parallaxObject, rate );
    });
  }

  // パララックスの処理を実行
  parallax( $('.partner__tmbInner'), 12 );
});


// ===========================================
// パートナーのスライドショー
// ===========================================
$(function () {
  $('.partnerSlide').each(function(){
    const slide = $(this);

    slide.slick({
      autoplay: true,
      autoplaySpeed: 0,
      arrows: false,
      slidesToShow: 1,
      cssEase: 'linear',
      pauseOnFocus: false,
      pauseOnHover: false,
      speed: 30000,
    });
  });
});


// ===========================================
// 事業・施設の紹介のレイアウト
// ===========================================
$(function () {
  let beforeWidth = $(window).width();

  function tileSet( head ) {
    head.css({ height: 'auto' });

    if( $(window).width() > 1024 ) {
      head.tile(3);
    }
  }

  $('.informationList').each(function(){
    const head = $(this).find('.informationListHead');

    tileSet( head );

    $(window).on('resize', function(){
      if( beforeWidth !== $(window).width() ) {
        tileSet( head );
      }
      beforeWidth = $(window).width();
    });
  });
});


// ===========================================
// 採用情報のインタラクション
// ===========================================
$(function () {
  function wideJudge(bnr) {
    const scroll = $(window).scrollTop();

    if(
      scroll > 800 &&
      $(window).width() <= 640
    ) {
      bnr.addClass('wide');
    } else {
      bnr.removeClass('wide');
    }
  }

  $('.floatingBnr').each(function(){
    const bnr = $(this);
    let scroll = $(window).scrollTop();

    wideJudge(bnr);

    $(window).on('scroll', function(){
      wideJudge(bnr);
    });
  });
});